
import {
  Component,
  Vue
} from 'vue-property-decorator'
import BaseService from '../services/base-service'
import LocationService from '../services/location-service'
import ActivityService from '../services/activity-service'
import {
  ToastModule
} from '@/store/modules/ToastModule'
import {
  BreadcrumbModule
} from '@/store/modules/BreadcrumbModule'
  @Component({
    name: 'DeliveryDispatch',
    components: {}
  })
export default class DeliveryDispatch extends Vue {
    protected isTableBusy = false
    protected searchTable = null
    protected locations: any = []

    protected dispatch: any = {
      id: null,
      dispatch_date: null,
      notes: []
    }

    protected fields: any = [{
      key: '#',
      label: '#',
      thStyle: {
        width: '2%'
      },
      class: 'text-center'
    },
    {
      key: 'vehicle_number',
      label: 'Bowser',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'vehicle_capacity',
      label: 'Capacity',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'location',
      label: 'Location',
      thStyle: {
        width: '12%'
      }
    },
    {
      key: 'seal_up',
      thStyle: {
        width: '30%'
      }
    },
    {
      key: 'seal_down',
      thStyle: {
        width: '30%'
      }
    },
    {
      key: 'action',
      label: '',
      thStyle: {
        width: '5%'
      },
      class: 'text-center'
    }
    ]

    created () {
      this.boot()
    }

    protected boot () {
      BreadcrumbModule.setBreadcrumb(this.$route)
      this.populateDispatch()
      this.populateYards()
    }

    protected populateDispatch () {
      this.isTableBusy = true
      BaseService.getDispatch('Bowser').then((response) => {
        this.isTableBusy = false

        this.dispatch.id = response.data.id
        this.dispatch.dispatch_date = response.data.dispatch_date
        response.data.notes.forEach((note) => {
          if (note.status === 'approved') {
            this.dispatch.notes.push({
              id: note.id,
              dispatch_id: note.dispatch_id,
              vehicle_number: note.vehicle_number,
              vehicle_capacity: note.vehicle_capacity,
              location: note.location_id === null ? {} : { id: note.location_id, name: note.location_name },
              seal_up: note.seal_up,
              seal_down: note.seal_down
            })
          }
        })
      })
    }

    protected save (tableIndex: number, item: any) {
      const note = {
        id: item.id,
        dispatch_id: item.dispatch_id,
        location_id: item.location.id,
        location_name: item.location.name,
        seal_up: item.seal_up.toString(),
        seal_down: item.seal_down.toString(),
        status: 'dispatched',
        modified_user: item.modified_user
      }
      ActivityService.update(note).then((response) => {
        this.dispatch.notes.splice(tableIndex, 1)
        ToastModule.message(response.data.message)
      }).catch(error => {
        console.log(error)
      })
    }

    protected populateYards () {
      LocationService.getYards().then((response) => {
        response.data.forEach(item => {
          this.locations.push({
            value: {
              id: item.id,
              name: item.name
            },
            text: item.name
          })
        })
      })
    }

    public reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}

