import APIService from '@/services/api-service'
const resource = '/dispatch'

class LocationService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getPorts () {
    return this.connector.get(`${resource}/ports`)
  }

  public getYards () {
    return this.connector.get(`${resource}/yards`)
  }

  public getPlants () {
    return this.connector.get(`${resource}/plants`)
  }
}

export default new LocationService()
